<template>
  <div class="side_menu">
    <a-layout-sider theme="light" breakpoint="lg" :collapsed="collapsed">
      <a-menu :selected-keys="defaultSelectedKeys" @collapse="onCollapse" @menuItemClick="onClickMenuItem">
        <a-menu-item key="0_1" disabled>
          易创管理系统
        </a-menu-item>
        <template v-for="menu in menuJsonData" :key="menu.id">
          <a-sub-menu v-if="menu.childList.length" :key="menu.id">
            <template #icon>
              <div class="img_box">
                <img :src="menu.icon" />
              </div>
            </template>
            <template #title>{{ menu.name }}</template>
            <template v-for="child in menu.childList" :key="child.id">
              <a-menu-item class="slot_item_title">
                {{ child.name }}
              </a-menu-item>
            </template>
          </a-sub-menu>
          <a-menu-item v-else :key="menu.id">
            <template #icon>
              <div class="img_box">
                <img :src="menu.icon" />
              </div>
            </template>
            {{ menu.name }}
          </a-menu-item>
        </template>
        <!-- #region -->
        <!-- //测试注释start-->
        <!-- <a-menu-item key="0_2">
          <template #icon>
            <div class="img_box">
              <img src="../../assets/sideMenu/icon_shuju.png" />
            </div>
          </template>
          数据管理
        </a-menu-item>
        <a-sub-menu key="1">
          <template #icon>
            <div class="img_box">
              <img src="../../assets/sideMenu/icon_shangpin.png" />
            </div>
          </template>
          <template #title>仓库管理</template>
          <a-menu-item key="1_1" class="slot_item_title">商品列表</a-menu-item>
          <a-menu-item key="1_2" class="slot_item_title">商品分类</a-menu-item>
          <a-menu-item key="1_3" class="slot_item_title">仓库管理</a-menu-item>
        </a-sub-menu>
        <a-sub-menu key="2">
          <template #icon>
            <div class="img_box">
              <img src="../../assets/sideMenu/icon_shangcheng.png"></img>
            </div>
          </template>
          <template #title>商城</template>
          <a-menu-item key="2_1" class="slot_item_title">店铺信息</a-menu-item>
          <a-menu-item key="2_2" class="slot_item_title">商品管理</a-menu-item>
        </a-sub-menu>
        <a-sub-menu key="3">
          <template #icon>
            <div class="img_box">
              <img src="../../assets/sideMenu/icon_tuiguang.png"></img>
            </div>
          </template>
          <template #title>客户管理</template>
          <a-menu-item key="3_1" class="slot_item_title">客户列表</a-menu-item>
          <a-menu-item key="3_2" class="slot_item_title">供应商列表</a-menu-item>
        </a-sub-menu>
        <a-sub-menu key="4">
          <template #icon>
            <div class="img_box">
              <img src="../../assets/sideMenu/icon_kaidan.png"></img>
            </div>
          </template>
          <template #title>销货开单</template>
          <a-menu-item key="4_1" class="slot_item_title">销货开票</a-menu-item>
          <a-menu-item key="4_2" class="slot_item_title">进货开票</a-menu-item>
          <a-menu-item key="4_3" class="slot_item_title">退货开票</a-menu-item>
        </a-sub-menu>
        <a-menu-item key="5">
          <template #icon>
            <div class="img_box">
              <img src="../../assets/sideMenu/icon_xiaohou.png"></img>
            </div>
          </template>
          订单管理
        </a-menu-item> -->
        <!-- //测试注释end -->


        <!-- //注释start -->
        <!-- <a-menu-item key="8">
          <template #icon>
            <div class="img_box">
              <img src="../../assets/sideMenu/icon_zhanghao.png"></img>
            </div>
          </template>
          子账号设置
        </a-menu-item> -->
        <!-- //注释end -->
        <!-- //测试注释start -->
        <!-- <a-sub-menu key="6">
          <template #icon>
            <div class="img_box">
              <img src="../../assets/sideMenu/icon_tousu.png"></img>
            </div>
          </template>
          <template #title>投诉建议</template>
          <a-menu-item key="6_1" class="slot_item_title">联系客服</a-menu-item>
          <a-menu-item key="6_2" class="slot_item_title">意见反馈</a-menu-item>
        </a-sub-menu>
        <a-sub-menu key="9" v-if="isRoleIdOne == 1">
          <template #icon>
            <div class="img_box">
              <img src="../../assets/sideMenu/icon_shangcheng.png"></img>
            </div>
          </template>
          <template #title>运营管理</template>
          <a-menu-item key="9_1" class="slot_item_title">店铺列表</a-menu-item>
          <a-menu-item key="9_2" class="slot_item_title">店铺订单</a-menu-item>
          <a-menu-item key="9_3" class="slot_item_title">角色管理</a-menu-item>
          <a-menu-item key="9_4" class="slot_item_title">管理员列表</a-menu-item>
        </a-sub-menu> -->
        <!-- //测试注释end -->

        <!-- //注释start -->
        <!-- <a-menu-item key="7">
          <template #icon>
            <div class="img_box">
              <img src="../../assets/sideMenu/icon_shezhi.png"></img>
            </div>
          </template>
          系统设置
        </a-menu-item> -->
        <!-- //注释end -->

        <!-- #endregion -->

      </a-menu>
    </a-layout-sider>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, getCurrentInstance } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
const Api = getCurrentInstance()?.appContext.config.globalProperties.$Api

const router = useRouter();
const route = useRoute();
const store = useStore();

const collapsed = ref(false);
const defaultSelectedKeys = ref(['0_2']); // 默认选中的菜单项
const defaultOpenKeys = ref(['1']); // 默认展开的菜单项

const menuJsonData = ref([])
// "data": [
// {
//   "id": "0_2",
//   "name": "数据管理",
//   "icon": "https://ycapi.zgyichuang.com/test/1721526881769_iI9LTL.png",
//   "path": "/dataManagement"
// },
// {
//   "id": "1",
//   "name": "仓库管理",
//   "icon": "https://ycapi.zgyichuang.com/test/1721526933708_UUtUED.png",
//   "childList": [
//     {
//       "id": "1_1",
//       "name": "商品列表",
//       "path": "/commodityManagement/listOfProducts"
//     },
//     {
//       "id": "1_2",
//       "name": "商品分类",
//       "path": "/commodityManagement/productClassification"
//     },
//     {
//       "id": "1_3",
//       "name": "仓库管理",
//       "path": "/commodityManagement/warehouseManagement"
//     }
//   ]
// },
// {
//   "id": "2",
//   "name": "商城",
//   "icon": "https://ycapi.zgyichuang.com/test/1721526955710_7VzJL9.png",
//   "childList": [
//     {
//       "id": "2_1",
//       "name": "店铺信息",
//       "path": "/shoppingMall/storeInformation"
//     },
//     {
//       "id": "2_2",
//       "name": "商品管理",
//       "path": "/shoppingMall/mallMerchandiseManagement"
//     }
//   ]
// },
// {
//   "id": "3",
//   "name": "客户管理",
//   "icon": "https://ycapi.zgyichuang.com/test/1721526975128_JNLqsP.png",
//   "childList": [
//     {
//       "id": "3_1",
//       "name": "客户列表",
//       "path": "/customerManagement/customerList"
//     },
//     {
//       "id": "3_2",
//       "name": "供应商列表",
//       "path": "/customerManagement/suppliersList"
//     }
//   ]
// },
// {
//   "id": "4",
//   "name": "销货开单",
//   "icon": "https://ycapi.zgyichuang.com/test/1721526988431_NIZDy5.png",
//   "childList": [
//     {
//       "id": "4_1",
//       "name": "销货开票",
//       "path": "/invoiceForSales/invoicingForSales"
//     },
//     {
//       "id": "4_2",
//       "name": "进货开票",
//       "path": "/invoiceForSales/invoicingForIncomingGoods"
//     },
//     {
//       "id": "4_3",
//       "name": "退货开票",
//       "path": "/invoiceForSales/returnBilling"
//     }
//   ]
// },
// {
//   "id": "5",
//   "name": "订单管理",
//   "icon": "https://ycapi.zgyichuang.com/test/1721527049825_USjyL-.png",
//   "path": "/orderManagement"
// },
// {
//   "id": "6",
//   "name": "投诉建议",
//   "icon": "https://ycapi.zgyichuang.com/test/1721527068146_2S3D0C.png",
//   "childList": [
//     {
//       "id": "6_1",
//       "name": "联系客服",
//       "path": "/complaintSuggestion/contactCustomerService"
//     },
//     {
//       "id": "6_2",
//       "name": "意见反馈",
//       "path": "/complaintSuggestion/feedback"
//     }
//   ]
// },
// {
//   "id": "9",
//   "name": "运营管理",
//   "icon": "https://ycapi.zgyichuang.com/test/1721527079500_v3NuDP.png",
//   "childList": [
//     {
//       "id": "9_1",
//       "name": "店铺列表",
//       "path": "/storeManagement/stores"
//     },
//     {
//       "id": "9_2",
//       "name": "店铺订单",
//       "path": "/storeManagement/order"
//     },
//     {
//       "id": "9_3",
//       "name": "角色管理",
//       "path": "/storeManagement/identityManager"
//     },
//     {
//       "id": "9_4",
//       "name": "管理员列表",
//       "path": "/storeManagement/adminList"
//     }
//     {
//       "id": "9_5",
//       "name": "广告/视频",
//       "path": "/storeManagement/adsAndVideos"
//     }
//   ]
// }
// ]
const routesMap = ref({})
// {
// '0_2': '/dataManagement',
// '1_1': '/commodityManagement/listOfProducts',
// '1_2': '/commodityManagement/productClassification',
// '1_3': '/commodityManagement/warehouseManagement',
// '2_1': '/shoppingMall/storeInformation',
// '2_2': '/shoppingMall/mallMerchandiseManagement',
// '3_1': '/customerManagement/customerList',
// '3_2': '/customerManagement/suppliersList',
// '4_1': '/invoiceForSales/invoicingForSales',
// '4_2': '/invoiceForSales/invoicingForIncomingGoods',
// '4_3': '/invoiceForSales/returnBilling',
// '5': '/orderManagement',
// '6_1': '/complaintSuggestion/contactCustomerService',
// '6_2': '/complaintSuggestion/feedback',
// '7': '/systemSettings',
// '8': '/subaccountSettings',
// '9_1': '/storeManagement/stores',
// '9_2': '/storeManagement/order',
// '9_3': '/storeManagement/identityManager',
// '9_4': '/storeManagement/adminList',
// '9_5': '/storeManagement/adsAndVideos'
// };

// 遍历菜单数据并填充 routesMap



const updateMenuState = (path) => {
  const key = Object.keys(routesMap.value).find(key => routesMap.value[key] === path);

  if (key) {
    defaultSelectedKeys.value = [key]; // 确保 defaultSelectedKeys 是数组
  }

};
const getPower = () => {
  // if (store.state.isGetMenu) return
  menuJsonData.value = store.state.permissions
  menuJsonData.value.forEach(item => {
    if (item.path) {
      routesMap.value[item.id] = item.path;
    }
    if (item.childList) {
      item.childList.forEach(child => {
        routesMap.value[child.id] = child.path;
      });
    }
  });

  // console.log('获取了', menuJsonData.value, routesMap.value);
  store.commit("changeMenuStatus", true);
}
onMounted(async () => {
  getPower()
  collapsed.value = store.state.isCollapsed;
  const path = store.state.currentPath || route.path;
  // 检查路径对应的权限
  const key = Object.keys(routesMap.value).find(key => routesMap.value[key] === path);
  console.log(key);
  // updateMenuState(path);
  // router.push(path);
  if (key) {
    if (isRoleIdOne.value == 1 || (key !== '9_1' && key !== '9_2')) {
      updateMenuState(path);
      router.push(path);
    } else {
      return
    }
  } else {
    // 如果路径不存在，跳转到 404 页面
    router.push('/NotFound');
  }

});


watch(route, (newRoute) => {
  const path = newRoute.path;
  updateMenuState(path);
});

const onCollapse = (val, type) => {
  collapsed.value = val;
  store.commit('changeCollapsed', val); // 确保这个 mutation 存在
};

const onClickMenuItem = (key) => {
  const path = routesMap.value[key];
  if (path) {
    store.dispatch('updateCurrentPath', path);
    console.log(path);
    router.push(path);
    updateMenuState(path);
  }
};

const isRoleIdOne = ref(0)
isRoleIdOne.value = JSON.parse(localStorage.getItem("userInfo")).roleid
</script>

<style lang="scss" scoped>
.side_menu {
  height: 100vh;

  .slot_box {
    height: 40px;

    .img_box {
      box-sizing: border-box;

      >img {
        width: 13px;
        height: 13px;
        transform: translateY(1px);
      }
    }

    .slot_title {
      margin-left: 5px;
      font-size: 15px;
    }
  }

  .slot_item_title {
    font-size: 13px;
  }

  .arco-layout-sider {
    height: 100vh;

    .arco-menu-item {
      display: flex;
      align-items: center;
      width: 100%;
    }

    .arco-menu-disabled {
      font-size: 18px;
      color: #333333;
      font-family: Abel, Abel;
      padding-bottom: 15px;
      margin-top: 10px;
    }

    .arco-layout-sider-children {
      .arco-menu {
        height: 100%;

        .arco-menu-inner {
          .arco-menu-selected {
            background: #6784fd;
            border-radius: 0px 20px 20px 0px;
            font-size: 13px;
            color: #ffffff;
          }

          .arco-menu-inline-content>.arco-menu-item-selected {
            background: rgba(103, 132, 253, 0.05);
            font-size: 14px;
            color: #6784fd;
          }
        }

        :deep(.arco-menu-collapse-button) {
          width: 32px;
          height: 32px;
          border-radius: 50%;
        }
      }
    }

    img {
      width: 15px;
      height: 15px;
    }
  }
}
</style>
