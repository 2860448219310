<template>
  <div class="list_products flex f-c">
    <div class="list_content">
      <div class="list_content_header flex f-x-b">
        <div class="flex">
          <div
            class="list_item"
            v-for="(item, index) in listHeader"
            :key="index"
            @click="handelChangeHeaderListItem(item, index)"
            :style="{ color: currentSelectIndex === item.id ? ' #6784FD' : '' }"
          >
            {{ item.title }}
          </div>
        </div>
        <div class="center_item" style="transform: translateY(-15px)">
          <a-button
            type="primary"
            status="warning"
            @click="addEvent"
            style="height: 40px"
          >
            <template #icon>
              <icon-plus />
            </template>
            添加
          </a-button>
        </div>
      </div>
      <template v-if="currentSelectIndex == 1">
        <a-space direction="vertical" size="large" fill>
          <a-table
            :columns="columnOne"
            :data="dataOne"
            :pagination="paginationOne"
            :bordered="false"
            :stripe="true"
          >
            <template #shopName="{ record, rowIndex }">
              <span>{{ record.shopName }}</span>
              <a-image
                width="40"
                height="40"
                :src="record.image"
                style="margin-left: 10px"
              />
            </template>
            <template #status="{ record, rowIndex }">
              <a-switch
                :default-checked="record.status == 0"
                :style="{ width: '60px' }"
                @change="ChannelMergerNode($event, record)"
              />
            </template>
            <template #controls="{ record, rowIndex }">
              <div
                class="flex"
                v-if="record.editable"
                style="white-space: nowrap"
              >
                <a-button type="primary" @click="saveRecord(record)"
                  >保存</a-button
                >
                <a-button
                  status="danger"
                  @click="cancelRecord(record.id)"
                  style="margin-left: 5px"
                  >取消</a-button
                >
              </div>
              <div v-else class="flex">
                <a-button
                  type="primary"
                  @click="editRecord(record, 1)"
                  style="margin-left: 5px"
                  >编辑</a-button
                >
                <a-button
                  status="danger"
                  @click="delAds(record.id, rowIndex)"
                  style="margin-left: 5px"
                  >删除</a-button
                >
              </div>
            </template>
          </a-table>
        </a-space>
      </template>
      <template v-else-if="currentSelectIndex == 2">
        <a-space direction="vertical" size="large" fill>
          <a-table
            :columns="columnTwo"
            :data="dataTwo"
            :pagination="paginationTwo"
            :bordered="false"
            :stripe="true"
          >
            <template #introduce="{ record, rowIndex }">
              <span>{{ record.introduce }}</span>

              <!-- <video :src="record.video" width="100" height="80" controls></video> -->
            </template>
            <template #controls="{ record, rowIndex }">
              <a-button
                status="danger"
                @click="delVideo(record.id, rowIndex)"
                style="margin-left: 5px"
                >删除</a-button
              >
            </template>
          </a-table>
        </a-space>
      </template>
      <!-- //添加广告 -->
      <a-modal
        :footer="null"
        v-model:visible="addAdsPop"
        :width="600"
        :title="adsTitle"
        alignCenter
        draggable
        escToClose
      >
        <a-form
          :model="uploadAdsForm"
          @submit="handleSubmitAddAds"
          class="flex f-x-c"
        >
          <a-form-item>
            <div class="flex a-i-c" style="padding-left: 20px">
              <div class="item_desc desc_right">选择店铺:</div>
              <a-select
                :style="{ width: '260px', marginLeft: '15px' }"
                value-key="id"
                :placeholder="editAdsDesc ? editAdsDesc : '请选择店铺'"
                :loading="storeLoading"
                v-model="selectStore"
                @change="storeChange"
              >
                <a-option
                  v-for="item of storeOptions"
                  :value="item"
                  :label="item.name"
                />
              </a-select>
            </div>
          </a-form-item>
          <a-form-item field="imgPath">
            <div style="padding-left: 20px" class="flex a-i-c">
              <div class="good_item_desc">店铺广告:</div>
              <a-upload
                class="upload"
                action="https://ycapi.zgyichuang.com/api/sys/oss/upload"
                accept="image/png, image/jpeg"
                :fileList="file"
                @success="onSuccessUploadImg"
                list-type="picture-card"
                :show-file-list="false"
              >
                <template #upload-button>
                  <div
                    :class="`arco-upload-list-item${
                      file && file.status === 'error'
                        ? ' arco-upload-list-item-error'
                        : ''
                    }`"
                  >
                    <div
                      class="arco-upload-list-picture custom-upload-avatar"
                      v-for="item in file"
                      style="width: 160px"
                    >
                      <img :src="item.url" />
                      <IconEdit />
                    </div>
                    <div
                      class="arco-upload-picture-card"
                      v-if="file.length < 1"
                      style="width: 160px"
                    >
                      <div class="arco-upload-picture-card-text">
                        <!-- <IconPlus size="28" strokeWidth="2" /> -->
                        <icon-image size="48" strokeWidth="2" />
                        <div
                          style="
                            font-weight: 600;
                            font-size: 12px;
                            color: #999999;
                          "
                        >
                          点击上传
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style="font-weight: 600; font-size: 12px; color: #999999"
                  >
                    图片仅支持jpg、png格式
                  </div>
                </template>
              </a-upload>
            </div>
          </a-form-item>
          <a-form-item style="text-align: center !important; padding: 0 80px">
            <a-button
              type="outline"
              status="primary"
              size="large"
              style="margin-right: 20px"
              @click="addAdsPop = false"
              >取消</a-button
            >
            <a-button html-type="submit" type="primary" size="large"
              >确定</a-button
            >
          </a-form-item>
        </a-form>
      </a-modal>
      <!-- //添加视频 -->
      <a-modal
        :footer="null"
        v-model:visible="addVideoPop"
        :width="600"
        :title="videoTitle"
        alignCenter
        draggable
        escToClose
      >
        <a-form
          :model="uploadVideoForm"
          @submit="handleSubmitAddVideo"
          class="flex f-x-c"
        >
          <a-form-item>
            <div class="flex a-i-c" style="padding-left: 20px">
              <div class="good_item_desc">描述:</div>
              <a-input
                :style="{ width: '250px' }"
                placeholder="请输入视频描述"
                allow-clear
                v-model="uploadVideoForm.introduce"
              />
            </div>
          </a-form-item>
          <a-form-item field="imgPath">
            <div style="padding-left: 20px" class="flex a-i-c">
              <div class="good_item_desc">视频:</div>
              <a-upload
                class="upload"
                action="https://ycapi.zgyichuang.com/api/sys/oss/upload"
                accept="video/*"
                :fileList="file"
                @success="onSuccessUploadVideo"
                list-type="picture-card"
                :show-file-list="false"
              >
                <template #upload-button>
                  <div
                    :class="`arco-upload-list-item${
                      file && file.status === 'error'
                        ? ' arco-upload-list-item-error'
                        : ''
                    }`"
                  >
                    <div
                      class="arco-upload-list-picture custom-upload-avatar"
                      v-for="item in file"
                      style="width: 250px; height: 200px"
                    >
                      <video
                        :src="item.url"
                        controls
                        width="250"
                        height="200"
                        preload="auto"
                      ></video>
                      <IconEdit />
                    </div>
                    <div
                      class="arco-upload-picture-card"
                      v-if="file.length < 1"
                      style="width: 250px; height: 200px"
                    >
                      <div class="arco-upload-picture-card-text">
                        <!-- <IconPlus size="28" strokeWidth="2" /> -->
                        <icon-live-broadcast size="48" strokeWidth="2" />
                        <div
                          style="
                            font-weight: 600;
                            font-size: 12px;
                            color: #999999;
                          "
                        >
                          点击上传
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </a-upload>
            </div>
          </a-form-item>
          <a-form-item style="text-align: center !important; padding: 0 80px">
            <a-button
              type="outline"
              status="primary"
              size="large"
              style="margin-right: 20px"
              @click="addVideoPop = false"
              >取消</a-button
            >
            <a-button html-type="submit" type="primary" size="large"
              >确定</a-button
            >
          </a-form-item>
        </a-form>
      </a-modal>
    </div>
  </div>
</template>
<script setup>
import {
  ref,
  watch,
  toRefs,
  reactive,
  getCurrentInstance,
  onMounted,
} from "vue";
import {
  IconSearch,
  IconRefresh,
  IconPrinter,
  IconPlus,
  IconImage,
  IconLiveBroadcast,
} from "@arco-design/web-vue/es/icon";
import { customRef } from "vue";
const Message =
  getCurrentInstance().appContext.config.globalProperties.$message;
const Api = getCurrentInstance()?.appContext.config.globalProperties.$Api;
const Com = getCurrentInstance()?.appContext.config.globalProperties.$Com;

onMounted(() => {
  handlePageChangeOne();
  getStore();
});

//#region 头部选择
const listHeader = [
  {
    title: "广告配置",
    id: 1,
  },
  {
    title: "视频教程",
    id: 2,
  },
];
const currentSelectIndex = ref(1);
//头部子项点击
const handelChangeHeaderListItem = (item, index) => {
  if (currentSelectIndex.value == item.id) return;
  currentSelectIndex.value = item.id;
  file.value = [];
  if (currentSelectIndex.value == 1) {
    handlePageChangeOne(
      paginationOne.value.current === undefined
        ? 1
        : paginationOne.value.current,
      10
    );
  } else {
    handlePageChangeTwo(
      paginationOne.value.current === undefined
        ? 1
        : paginationOne.value.current,
      10
    );
  }
};
//#endregion
//#region 店铺列表
const storeOptions = ref([]);
const storeLoading = ref(false);
const selectStore = ref("");
const getStore = () => {
  storeLoading.value = true;
  Api.getShopList({
    pageSize: 1000,
    status: 0,
  }).then((res) => {
    storeOptions.value = res.data.datalist;
    storeLoading.value = false;
  });
};
const storeChange = () => {
  editAdsId.value = selectStore.value.id;
  console.log(editAdsId.value);
};
//#endregion
//#region   查询表单 搜索 重制
const columnOne = [
  {
    title: "排序",
    dataIndex: "id",
  },
  {
    title: "店铺",
    dataIndex: "shopName",
    slotName: "shopName",
  },
  {
    title: "状态",
    dataIndex: "status",
    slotName: "status",
  },
  {
    title: "操作",
    slotName: "controls",
  },
];
const dataOne = ref([]);
const paginationOne = ref({
  total: 0, // 数据总条目数
  showTotal: true, // 是否显示总条目数
  defaultPageSize: 10, // 默认每页显示条目数
  pageSize: 10, // 当前每页显示条目数
  current: 1, // 当前页码
  showJumper: true,
  onChange: (page, pageSize) => {
    handlePageChangeOne(page, pageSize);
  },
});
const handlePageChangeOne = (page, pageSize) => {
  console.log("page:", page, "pageSize:", pageSize);
  const pageIndex = page ? page : paginationOne.value.current;
  Api.getAdsAndVideo({
    pageIndex: pageIndex,
    // status: 0,
    type: currentSelectIndex.value,
  })
    .then((res) => {
      paginationOne.value.current = page; // 更新当前页码
      res.data.datalist.forEach((item) => {
        item.editable = false;
      });
      paginationOne.value.total = res.data.total; // 更新总条目数
      dataOne.value = [...res.data.datalist];
      console.log(dataOne.value);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
};

//#endregion
//#region   查询表单 搜索 重制
const columnTwo = [
  {
    title: "排序",
    dataIndex: "id",
  },
  {
    title: "视频描述",
    dataIndex: "introduce",
    slotName: "introduce",
  },
  {
    title: "操作",
    slotName: "controls",
  },
];
const dataTwo = ref([]);
const paginationTwo = ref({
  total: 0, // 数据总条目数
  showTotal: true, // 是否显示总条目数
  defaultPageSize: 10, // 默认每页显示条目数
  pageSize: 10, // 当前每页显示条目数
  current: 1, // 当前页码
  showJumper: true,
  onChange: (page, pageSize) => {
    handlePageChangeTwo(page, pageSize);
  },
});
const handlePageChangeTwo = (page, pageSize) => {
  console.log("page:", page, "pageSize:", pageSize);
  const pageIndex = page ? page : paginationTwo.value.current;
  Api.getAdsAndVideo({
    pageIndex: pageIndex,
    status: 0,
    type: currentSelectIndex.value,
  })
    .then((res) => {
      console.log(res);
      paginationTwo.value.current = page; // 更新当前页码
      res.data.datalist.forEach((item) => {
        item.editable = false;
      });
      paginationTwo.value.total = res.data.total; // 更新总条目数
      dataTwo.value = [...res.data.datalist];
      console.log(dataTwo.value);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
};

//#endregion
//#region
const addAdsPop = ref(false);
const adsTitle = ref("添加广告");
const uploadAdsForm = ref({
  img: "",
});
const addVideoPop = ref(false);
const videoTitle = ref("添加视频");

const uploadVideoForm = ref({
  video: "",
  introduce: "",
  type: 2,
});
const addEvent = () => {
  if (currentSelectIndex.value == 1) {
    addAdsPop.value = true;
    file.value = [];
    uploadAdsForm.value.img = "";
    editAdsId.value = "";
    selectStore.value = "";
    currentEditId.value = "";
    adsTitle.value = "添加广告";
    editAdsDesc.value = "";
  } else if (currentSelectIndex.value == 2) {
    addVideoPop.value = true;
  }
};
const handleSubmitAddAds = (data) => {
  if (adsTitle.value == "添加广告") {
    if (!uploadAdsForm.value.img || !selectStore.value.id) return;
    Api.saveAdsAndVideo({
      shopid: selectStore.value.id,
      image: uploadAdsForm.value.img,
      type: 1,
      // userid: JSON.parse(localStorage.getItem("userInfo")).id,
    }).then((res) => {
      console.log(res);
      if (res.code == 200) {
        Message.success(res.message);
      } else {
        Message.warning(res.message);
      }
      handlePageChangeOne(
        paginationOne.value.current === undefined
          ? 1
          : paginationOne.value.current,
        10
      );
      addAdsPop.value = false;
    });
  } else if (adsTitle.value == "编辑广告") {
    addAdsPop.value = false;
    selectStore.value = {
      id: editAdsId.value,
    };
    console.log(selectStore.value, editAdsId.value);

    if (!uploadAdsForm.value.img || !selectStore.value.id) return;
    Api.updateAdsAndVideo({
      shopid: editAdsId.value,
      image: uploadAdsForm.value.img,
      id: currentEditId.value,
      // userid: JSON.parse(localStorage.getItem("userInfo")).id,
    }).then((res) => {
      console.log(res);
      if (res.code == 200) {
        Message.success(res.message);
      } else {
        Message.warning(res.message);
      }
      handlePageChangeOne(
        paginationOne.value.current === undefined
          ? 1
          : paginationOne.value.current,
        10
      );
    });
  }
};
const handleSubmitAddVideo = (data) => {
  Api.saveAdsAndVideo({
    ...uploadVideoForm.value,
  }).then((res) => {
    console.log(res);
    if (res.code == 200) {
      Message.success(res.message);
    } else {
      Message.warning(res.message);
    }
    handlePageChangeTwo(
      paginationOne.value.current === undefined
        ? 1
        : paginationOne.value.current,
      10
    );
    addVideoPop.value = false;
  });
};
//#endregion
//#region 上传
const file = ref([]);
const onSuccessUploadImg = (res, currentFile) => {
  const fileUrl = res.response.data.fileUrl;
  file.value = [];
  file.value.push({
    uid: res.uid,
    status: "done",
    url: fileUrl,
  });
  console.log(file.value);
  uploadAdsForm.value.img = file.value.map((file) => file.url).join(",");
  console.log(uploadAdsForm.value.img);
};

const onSuccessUploadVideo = (res, currentFile) => {
  const fileUrl = res.response.data.fileUrl;
  file.value = [];
  file.value.push({
    uid: res.uid,
    status: "done",
    url: fileUrl,
  });
  console.log(file.value);
  uploadVideoForm.value.video = file.value.map((file) => file.url).join(",");
  console.log(uploadVideoForm.value.video);
};

//#endregion
//#region 编辑 删除 保存
const editAdsDesc = ref("");
const editAdsId = ref("");
const currentEditId = ref("");
const editRecord = (item, num) => {
  file.value = [];
  uploadAdsForm.value.img = "";
  editAdsId.value = "";
  currentEditId.value = "";
  if (num == 1) {
    //广告
    console.log(item);
    addAdsPop.value = true;
    adsTitle.value = "编辑广告";
    editAdsDesc.value = item.shopName;
    // selectStore.value = {
    //     id: Number(item.id),   // 添加或更新 id 属性
    // };
    editAdsId.value = item.shopid;
    currentEditId.value = item.id;
    if (item.shopName) {
      selectStore.value = {
        id: item.shopName,
      };
    }
    uploadAdsForm.value.img = item.image;
    // file.value.url =
    console.log(file.value, uploadAdsForm.value.img);
    file.value.push({
      url: item.image,
    });
  } else {
    //视频
  }
};
//#endregion

//#region 修改状态
const ChannelMergerNode = (event, item) => {
  console.log(event);
  if (event) {
    item.status = 0;
  } else {
    item.status = 1;
  }
  console.log(event, item);
  Api.updateAdsAndVideo({
    id: item.id,
    status: item.status,
  })
    .then((res) => {
      console.log(res);
      Message.success("修改成功");
      handlePageChangeOne(
        paginationOne.value.current === undefined
          ? 1
          : paginationOne.value.current,
        10
      );
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
};
const delAds = (id, index) => {
  Api.deleteAdsAndVideo({
    ids: id,
  })
    .then((res) => {
      console.log(res);
      Message.success("删除成功");
      handlePageChangeOne(
        paginationOne.value.current === undefined
          ? 1
          : paginationOne.value.current,
        10
      );
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
};
const delVideo = (id, index) => {
  Api.deleteAdsAndVideo({
    ids: id,
  })
    .then((res) => {
      console.log(res);
      Message.success("删除成功");
      handlePageChangeTwo(
        paginationOne.value.current === undefined
          ? 1
          : paginationOne.value.current,
        10
      );
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
};
//#endregion
</script>
<style lang="scss" scoped>
.list_products {
  background: #ffffff;
  border-radius: 2px;
  height: 100%;
  box-sizing: border-box;
  padding: 15px 0;

  .item_desc,
  :deep(.arco-input-prepend) {
    font-weight: 400;
    white-space: nowrap;
    color: #000000;
    font-size: 14px;
    background-color: red;
    background: #ffffff !important;
    border: none;
  }

  .desc_width {
    width: 100px;
    width: 70px;
  }

  :deep(.arco-input-wrapper) .arco-input::placeholder,
  :deep(.arco-select-view-single) .arco-select-view-input::placeholder {
    font-size: 12px !important;
  }

  .list_content {
    flex: 1;
    padding: 0 1%;
    margin-top: 20px;

    .list_content_header {
      .center_item {
        // padding: 25px 10px 10px;
      }

      .list_item {
        font-size: 14px;
        color: #333333;
        padding: 13px 6px;
        border: 1px solid #e6e6e6;
        width: 106px;
        box-sizing: border-box;
        text-align: center;
        cursor: pointer;
      }

      .active {
        color: #6784fd;
      }
    }
  }
}
</style>
